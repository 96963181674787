<template>
  <div class="about">
    <v-col cols="12" sm="12" class="text-center">
      <h3>
        Спасибо, данные по оплате заказа №
        <h1>{{ nomerZakaza }}</h1>
        переданы для проверки. Рассылка будет осуществлена в будний день в
        первой половине дня.
      </h3>
      <h2>
        Ожидайте автоматический звонок уведомление за 5 минут до начала
        проведения.
      </h2>
      <h2 class="blue-grey--text">
        Если возникнут вопросы по оплате или содержанию анкеты мы обязательно
        свяжемся с вами.
      </h2>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "Success",
  props: {
    nomerZakaza: {
      type: Number,
    },
  },
};
</script>
